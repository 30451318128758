.body_pointer * {
  cursor: pointer;
}
.main-menu .main-menu__trigger {
  background: url("365c6a40f56084f5c374ac65aea80781.svg") 0 0 repeat;
  display: block;
  width: 29px;
  height: 25px;
  position: fixed;
  right: 31px;
  top: 65px;
  z-index: 99;
}
@media (min-width: 1024px) {
  .main-menu .main-menu__trigger {
    display: none;
  }
}
.main-menu .main-menu__close {
  background: url("85baf9c09867764994a68fa324fb46be.svg") 0 0 repeat;
  display: block;
  width: 23px;
  height: 23px;
  position: absolute;
  right: 30px;
  top: 65px;
  z-index: 100;
}
@media (min-width: 1024px) {
  .main-menu .main-menu__close {
    display: none;
  }
}
.main-menu .main-menu__popup {
  background: #fff;
  display: none;
  position: fixed;
  right: -110%;
  top: 0;
  width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 30px;
  z-index: 9999;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}
@media (min-width: 1024px) {
  .main-menu .main-menu__popup {
    display: block;
    position: relative;
    top: 0;
    right: 0;
    min-height: auto;
    box-shadow: none;
    padding-left: 18%;
    padding-right: 18%;
  }
}
.main-menu .main-menu__list {
  margin: 0;
  padding: 0;
}
@media (min-width: 1024px) {
  .main-menu .main-menu__list {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #000;
  }
}
.main-menu .main-menu__item {
  margin-top: 35px;
  margin-bottom: 55px;
  list-style-type: none;
  padding: 1px;
}
@media (min-width: 1024px) {
  .main-menu .main-menu__item {
    margin-top: 25px;
    margin-bottom: 35px;
    padding: 0;
  }
}
.main-menu .main-menu__item--offset {
  margin-top: 65px;
}
@media (min-width: 1024px) {
  .main-menu .main-menu__item--offset {
    margin-top: 25px;
  }
}
.main-menu .main-menu__link {
  font-size: 34px;
  font-weight: bold;
  color: #828282;
  line-height: 0.8;
  text-decoration: none;
}
@media (min-width: 1024px) {
  .main-menu .main-menu__link {
    font-size: 18px;
  }
}
.header {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 1024px) {
  .header {
    padding-left: 18%;
    padding-right: 18%;
    padding-bottom: 60px;
  }
}
.header .header__name {
  margin-left: 25px;
  margin-top: 55px;
  width: 100%;
  margin-bottom: 10px;
  font-size: 47px;
  font-weight: bold;
}
@media (min-width: 1024px) {
  .header .header__name {
    margin: 0;
    width: 300px;
  }
}
.header .header__main {
  margin-left: 31px;
  font-size: 18px;
  font-weight: 500;
}
@media (min-width: 1024px) {
  .header .header__main {
    align-self: end;
    margin: 0;
    margin-left: 120px;
    margin-bottom: 15px;
    width: 300px;
  }
}
.header .header__social {
  display: none;
}
@media (min-width: 1024px) {
  .header .header__social {
    display: flex;
    justify-content: space-between;
    width: 170px;
    margin-left: auto;
  }
}
.header .header__social .header__social-item img {
  width: 50px;
  height: 50px;
}
@media (min-width: 1024px) {
  .header .header__social .header__social-item {
    transform: scale(0.8);
  }
}
.header .header__photo-wrapper {
  display: flex;
  width: 100%;
  justify-content: right;
  padding-left: 15px;
  padding-right: 15px;
}
.header .header__photo {
  text-align: center;
  width: 100%;
}
@media (min-width: 1024px) {
  .header .header__photo {
    margin: 0;
    margin-top: 30px;
    width: 100%;
    height: auto;
  }
}
.header .header__photo--mobile {
  display: block;
}
@media (min-width: 1024px) {
  .header .header__photo--mobile {
    display: none;
  }
}
.header .header__photo--main {
  height: 495px;
  width: auto;
  display: none;
}
@media (min-width: 1024px) {
  .header .header__photo--main {
    display: block;
  }
}
.about {
  margin-top: 60px;
  padding-top: 10px;
  padding-bottom: 20px;
  text-align: center;
  background-color: #f6f6f6;
}
@media (min-width: 1024px) {
  .about {
    padding-top: 60px;
    padding-left: 18%;
    padding-right: 18%;
    padding-bottom: 100px;
  }
}
.about .about__header {
  margin-bottom: 40px;
  font-size: 34px;
  font-weight: bold;
}
@media (min-width: 1024px) {
  .about .about__header {
    margin-bottom: 60px;
  }
}
.about .about__text {
  margin-left: 35px;
  margin-right: 35px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.27;
}
.directions {
  margin-top: 50px;
  padding-top: 10px;
}
@media (min-width: 1024px) {
  .directions {
    padding-left: 18%;
    padding-right: 18%;
    padding-bottom: 100px;
  }
}
.directions .directions__header {
  text-align: center;
  font-size: 34px;
  font-weight: bold;
}
@media (min-width: 1024px) {
  .directions .directions__header {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
  }
}
.directions .directions__wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-left: 115px;
  padding-right: 115px;
}
@media (min-width: 1024px) {
  .directions .directions__wrapper {
    padding: 0;
    margin-left: -90px;
    margin-right: -90px;
  }
}
.directions .directions__feature {
  margin-top: 35px;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: normal;
  text-align: center;
  flex-grow: 1;
}
@media (min-width: 1024px) {
  .directions .directions__feature {
    margin-top: 25px;
    width: calc(100% / 3);
  }
}
.directions .directions__text {
  width: 150px;
  margin: auto;
}
.directions .directions__text--offset {
  margin-top: -8px;
}
@media (min-width: 1024px) {
  .directions .directions__text--offset {
    margin-top: 0;
  }
}
.directions .directions__text--long {
  width: 300px;
}
.education {
  margin-top: 10px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 100px;
  background-color: #f6f6f6;
}
@media (min-width: 1024px) {
  .education {
    padding-left: 18%;
    padding-right: 18%;
    padding-bottom: 70px;
  }
}
.education .education__header {
  margin-top: 25px;
  margin-bottom: 35px;
  text-align: center;
  font-size: 34px;
  font-weight: bold;
}
@media (min-width: 1024px) {
  .education .education__header {
    margin-top: 80px;
    margin-bottom: 40px;
  }
}
.education .education__item {
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.95;
}
@media (min-width: 1024px) {
  .education .education__item {
    margin-right: -18%;
    letter-spacing: -0.5px;
  }
}
.contacts {
  margin-top: 55px;
  padding-left: 85px;
  padding-right: 85px;
  text-align: center;
}
@media (min-width: 1024px) {
  .contacts {
    margin-top: 90px;
  }
}
.contacts .contacts__header {
  margin-bottom: 75px;
  font-size: 34px;
  font-weight: bold;
}
@media (min-width: 1024px) {
  .contacts .contacts__header {
    margin-bottom: 25px;
  }
}
.contacts .contacts__address {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
}
@media (min-width: 1024px) {
  .contacts .contacts__address {
    margin-bottom: 5px;
  }
}
.contacts .contacts__phone {
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
  color: #000;
  text-decoration: none;
}
@media (min-width: 1024px) {
  .contacts .contacts__phone {
    padding: 0;
  }
}
.contacts .contacts__email {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 40px;
  display: block;
  font-size: 18px;
  font-weight: 500;
  color: #000;
  text-decoration: none;
}
@media (min-width: 1024px) {
  .contacts .contacts__email {
    padding: 0;
    margin-bottom: 20px;
  }
}
.contacts .contacts__appointment {
  margin: 0;
  margin-bottom: 60px;
  background-color: #000;
  border: 2px solid #000;
  border-radius: 30px;
  color: #fff;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition-duration: 0.4s;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  width: 215px;
  user-select: none;
  position: relative;
  -webkit-tap-highlight-color: transparent;
}
@media (min-width: 1024px) {
  .contacts .contacts__appointment {
    width: 180px;
    padding: 12px 29px;
  }
  .contacts .contacts__appointment:hover {
    background-color: #fff;
    color: #000;
  }
}
.contacts .contacts__appointment:after {
  content: "";
  background: #f1f1f1;
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  border-radius: 30px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 50%;
  padding-right: 50%;
  opacity: 0;
  transition: all 0.8s;
  user-select: none;
}
.contacts .contacts__appointment:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}
@media (min-width: 1024px) {
  .contacts .contacts__appointment {
    margin-bottom: 15px;
  }
}
.contacts .contacts__social {
  margin-bottom: 80px;
  display: inline-flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 220px;
  padding-left: 20px;
  padding-right: 20px;
}
@media (min-width: 1024px) {
  .contacts .contacts__social {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }
}
.contacts .contacts__social .contacts__social-item {
  display: block;
  position: relative;
  -webkit-tap-highlight-color: transparent;
}
.contacts .contacts__social .contacts__social-item:after {
  content: "";
  background: #f1f1f1;
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  border-radius: 10px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 50%;
  padding-right: 50%;
  opacity: 0;
  transition: all 0.8s;
  user-select: none;
}
.contacts .contacts__social .contacts__social-item:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}
.contacts .contacts__social .contacts__social-item img {
  width: 50px;
  height: 50px;
}
@media (min-width: 1024px) {
  .contacts .contacts__social .contacts__social-item {
    transform: scale(0.8);
  }
}
.contacts .contacts__caption {
  padding-top: 10px;
  margin-left: -50px;
  margin-right: -50px;
  font-size: 14px;
  font-weight: 500;
  color: #828282;
  margin-bottom: 100px;
}
@media (min-width: 1024px) {
  .contacts .contacts__caption {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}
.popup {
  position: relative;
  background: #fff;
  padding: 20px;
  width: auto;
  max-width: 500px;
  margin: 20px auto;
  border-radius: 10px;
}
.popup .popup__header {
  text-align: center;
}
.popup .popup__body {
  text-align: center;
}
.callback-form {
  text-align: center;
}
.callback-form .callback-form__input {
  margin-bottom: 20px;
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  border: 1px solid #808080;
  border-radius: 20px;
}
.callback-form .callback-form__submit {
  background-color: #000;
  border: 2px solid #000;
  border-radius: 30px;
  color: #fff;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition-duration: 0.4s;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  width: 215px;
  user-select: none;
  position: relative;
  -webkit-tap-highlight-color: transparent;
}
@media (min-width: 1024px) {
  .callback-form .callback-form__submit {
    width: 180px;
    padding: 12px 29px;
  }
  .callback-form .callback-form__submit:hover {
    background-color: #fff;
    color: #000;
  }
}
.callback-form .callback-form__submit:after {
  content: "";
  background: #f1f1f1;
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  border-radius: 30px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 50%;
  padding-right: 50%;
  opacity: 0;
  transition: all 0.8s;
  user-select: none;
}
.callback-form .callback-form__submit:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}
html {
  color: #222;
  font-size: 1em;
  line-height: 1.3;
  font-family: Gilroy, Arial, sans-serif;
  overflow-x: hidden;
}
/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * Vendor-prefixed and regular ::selection selectors cannot be combined:
 * https://stackoverflow.com/a/16982510/7133471
 *
 * Customize the background color to match your design.
 */
::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}
::selection {
  background: #b3d4fc;
  text-shadow: none;
}
/*
 * A better looking default horizontal rule
 */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}
/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}
/*
 * Remove default fieldset styles.
 */
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
/*
 * Allow only vertical resizing of textareas.
 */
textarea {
  resize: vertical;
}
/* ==========================================================================
   Author's custom styles
   ========================================================================== */
/* ==========================================================================
   Helper classes
   ========================================================================== */
/*
 * Hide visually and from screen readers
 */
.hidden,
[hidden] {
  display: none !important;
}
/*
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 */
.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
/* 1 */
}
/*
 * Extends the .sr-only class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */
.sr-only.focusable:active,
.sr-only.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  white-space: inherit;
  width: auto;
}
/*
 * Hide visually and from screen readers, but maintain layout
 */
.invisible {
  visibility: hidden;
}
/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
.clearfix::before,
.clearfix::after {
  content: " ";
  display: table;
}
.clearfix::after {
  clear: both;
}
/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   https://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */
@media print {
  *,
  *::before,
  *::after {
    background: #fff !important;
    color: #000 !important;
/* Black prints faster */
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]::after {
    content: " (" attr(href) ")";
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  a[href^="#"]::after,
  a[href^="javascript:"]::after {
    content: "";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}
